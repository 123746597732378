export default {
	global: {
		appVersion: '1.2.0',
		appDefaultEntryPoint: 'home',
		baseUrl: '/es.lspi.LSPI'
	},
	menu: {
		keepExpanded: true,
		searchBtn: false,
		helpBtn: false,
		notificationsBtn: false
	}
};
