import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//IMPORTs

// GEN_REGISTER_COMPONENT_START
Vue.component('equipamiento-grid', () => import('@/components/equipamiento/EquipamientoGrid'));
Vue.component('equipamiento-form', () => import('@/components/equipamiento/EquipamientoForm'));
Vue.component('equipamiento-form-header', () => import('@/components/equipamiento/EquipamientoFormHeader'));

Vue.component('documentoaduanero-grid', () => import('@/components/documentoaduanero/DocumentoaduaneroGrid'));
Vue.component('documentoaduanero-form', () => import('@/components/documentoaduanero/DocumentoaduaneroForm'));
Vue.component('documentoaduanero-form-header', () => import('@/components/documentoaduanero/DocumentoaduaneroFormHeader'));

Vue.component('documentosaduaneroequipamiento-grid', () => import('@/components/documentosaduaneroequipamiento/DocumentosaduaneroequipamientoGrid'));
Vue.component('documentosaduaneroequipamiento-form', () => import('@/components/documentosaduaneroequipamiento/DocumentosaduaneroequipamientoForm'));
Vue.component('partida-grid', () => import('@/components/partida/PartidaGrid'));
Vue.component('partida-form', () => import('@/components/partida/PartidaForm'));
Vue.component('partida-form-header', () => import('@/components/partida/PartidaFormHeader'));
Vue.component('equipamientospartida-grid', () => import('@/components/equipamientospartida/EquipamientospartidaGrid'));
Vue.component('equipamientospartida-form', () => import('@/components/equipamientospartida/EquipamientospartidaForm'));
Vue.component('lsptraza-grid', () => import('@/components/lsptraza/LsptrazaGrid'));
Vue.component('lspregcamion-grid', () => import('@/components/lspregcamion/LspregcamionGrid'));
Vue.component('equipamientoqrequip-grid', () => import('@/components/equipamientoqrequip/EquipamientoqrequipGrid'));
Vue.component('vehiculospartida-grid', () => import('@/components/vehiculospartida/VehiculospartidaGrid'));
Vue.component('agenteaduana-grid', () => import('@/components/agenteaduana/AgenteaduanaGrid'));
Vue.component('agenteaduana-form', () => import('@/components/agenteaduana/AgenteaduanaForm'));
Vue.component('equipamientoagenteaduana-grid', () => import('@/components/equipamientoagenteaduana/EquipamientoagenteaduanaGrid'));
// GEN_REGISTER_COMPONENT_END

const puiRoutes = [
	{
		path: '/',
		redirect: 'home'
	},
	{
		path: 'home',
		component: () => import('@/components/home/LSPIWelcomePanel.vue')
	},
	{
		path: 'usersettings',
		component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserSettingsForm.vue')
	},
	{
		path: 'puiaudit',
		component: () => import('pui9-admin/src/components/config/puiaudit/PuiAuditGrid.vue')
	},
	{
		path: 'session',
		component: () => import('pui9-admin/src/components/config/puisession/PuiSessionGrid.vue')
	},
	{
		path: 'puiuser',
		component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserGrid.vue')
	},
	{
		path: '(.*/)?puiuser/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserForm.vue')
	},
	{
		path: 'puiprofile',
		component: () => import('pui9-admin/src/components/admin/puiprofile/PuiProfileGrid.vue')
	},
	{
		path: '(.*/)?puiprofile/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/admin/puiprofile/PuiProfileForm.vue')
	},
	{
		path: 'puifunctionality',
		component: () => import('pui9-admin/src/components/admin/puifunctionality/PuiFunctionalityGrid.vue')
	},
	{
		path: 'puivariable',
		component: () => import('pui9-admin/src/components/config/puivariable/PuiVariableGrid.vue')
	},
	{
		path: '(.*/)?puivariable/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/config/puivariable/PuiVariableForm.vue')
	},
	{ path: 'puilanguage', component: () => import('pui9-admin/src/components/config/puilanguage/PuiLanguageGrid.vue') },
	{
		path: '(.*/)?puilanguage/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/config/puilanguage/PuiLanguageForm.vue')
	},
	{
		path: 'puidocument',
		component: () => import('pui9-documents/src/components/PuiDocumentGrid.vue')
	},
	{
		path: '(.*/)?puidocument/:method/:pk',
		props: true,
		component: () => import('pui9-documents/src/components/PuiDocumentForm.vue')
	},
	{
		path: 'puidocgentemplate',
		component: () => import('pui9-docgen/src/components/puidocgen/PuiDocgenTemplateGrid.vue')
	},
	{
		path: '(.*/)?puidocgentemplate/:method/:pk',
		props: true,
		component: () => import('pui9-docgen/src/components/puidocgen/PuiDocgenTemplateForm')
	}
];

const appRoutes = [
	// GEN_ROUTES_START
	{
		path: 'equipamiento',
		component: () => import('@/components/equipamiento/EquipamientoGrid')
	},
	{
		path: '(.*)equipamiento/:method/:pk',
		props: true,
		component: () => import('@/components/equipamiento/EquipamientoForm')
	},
	{
		path: 'documentoaduanero',
		component: () => import('@/components/documentoaduanero/DocumentoaduaneroGrid')
	},
	{
		path: '(.*)documentoaduanero/:method/:pk',
		props: true,
		component: () => import('@/components/documentoaduanero/DocumentoaduaneroForm')
	},
	{
		path: 'documentosaduaneroequipamiento',
		component: () => import('@/components/documentosaduaneroequipamiento/DocumentosaduaneroequipamientoGrid')
	},
	{
		path: '(.*)documentosaduaneroequipamiento/:method/:pk',
		props: true,
		component: () => import('@/components/documentosaduaneroequipamiento/DocumentosaduaneroequipamientoForm')
	},
	{
		path: '(.*)partida/:method/:pk',
		props: true,
		component: () => import('@/components/partida/PartidaForm')
	},
	{
		path: 'equipamientospartida',
		component: () => import('@/components/equipamientospartida/EquipamientospartidaGrid')
	},
	{
		path: '(.*)equipamientospartida/:method/:pk',
		props: true,
		component: () => import('@/components/equipamientospartida/EquipamientospartidaForm')
	},
	{
		path: 'lsptraza',
		component: () => import('@/components/lsptraza/LsptrazaGrid')
	},
	{
		path: 'lsptraza_consulta(.*)',
		component: () => import('@/components/lsptraza/LsptrazaGrid')
	},
	{
		path: 'lsptraza_actualizar(.*)',
		component: () => import('@/components/lsptraza/LsptrazaGrid')
	},
	{
		path: 'lspregcamion',
		component: () => import('@/components/lspregcamion/LspregcamionGrid')
	},
	{
		path: 'lspregcamion_in(.*)',
		component: () => import('@/components/lspregcamion/LspregcamionGrid')
	},
	{
		path: 'lspregcamion_out(.*)',
		component: () => import('@/components/lspregcamion/LspregcamionGrid')
	},
	{
		path: 'lsptrazaconfronta',
		component: () => import('@/components/confronta/LsptrazaConfrontaForm.vue')
	},
	{
		path: 'vehiculospartida',
		component: () => import('@/components/vehiculospartida/VehiculospartidaGrid')
	},
	{
		path: 'agenteaduana',
		component: () => import('@/components/agenteaduana/AgenteaduanaGrid')
	},
	{
		path: '(.*)agenteaduana/:method/:pk',
		props: true,
		component: () => import('@/components/agenteaduana/AgenteaduanaForm')
	}

	// GEN_ROUTES_END
];

const puiNotFound = [{ path: '*', component: () => import('pui9-base/src/components/PuiNotFound') }];

function setAuthRequired(route) {
	return { ...route, meta: { ...route.meta, requiresAuth: true } };
}

const mergedRouter = {
	mode: 'history',
	hash: false,
	base: '/LSPI',
	routes: [
		{
			path: '/login',
			component: () => import('@/components/login/LSPILogin')
		},
		{
			path: `/`,
			component: () => import('pui9-base/src/components/PuiBaseLayout'),
			children: puiRoutes
				.concat(appRoutes)
				.concat(puiNotFound)
				.map(setAuthRequired)
		}
	]
};

export default new VueRouter(mergedRouter);
