export default {
	getDistributionAppData({ state, commit, getters }) {
		if (state.entorno.distribution) {
			console.log('ENTORNO OK');
			return;
		}

		const {
			$app: { $puiRequests, $puiNotify }
		} = this;

		try {
			console.log('ENTORNO LOADING');
			$puiRequests.getRequest(
				'/lspilogin/getDistributionAppData',
				null,
				response => {
					commit('setDistributionAppData', response.data);
					console.log('ENTORNO LOADED');
				},
				e => {
					console.log(e);
				}
			);
		} catch (error) {
			const message = getters.getTranslate('getDistributionAppData.error');
			$puiNotify.error(message);
		}
	}
};
